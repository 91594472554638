<template>
  <div class="">
    <div class="s-layout__index_header">
      <div class="btn-group">
        <button
          type="button"
          @click="$router.push({ name: 'discounts-index' })"
          class="btn"
          :class="$route.name == 'discounts-index' ? 'active' : ''"
        >
          <i class="bi bi-currency-bitcoin"></i>

          Remises
        </button>
      </div>
    </div>

    <br />
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
    window.location;
  },
};
</script>
